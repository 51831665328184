import React from 'react'

export const alignContent = (alignment) => {
    let alignContent = (
        alignment === 'Centered' || alignment === 'Center' ? 'text-center' : (
            alignment === 'Right Justified' || alignment === 'Right' ? 'text-right' :
                'left'
        )
    )
    return alignContent
}

export const bottomSpacing = (alignment) => {
    let bottomSpacing = (
        alignment === 'Small' ? 'bottom-small' : (
            alignment === 'Medium' ? 'bottom-medium' :
            alignment === 'Large' ? 'bottom-large' :  ''
        )
    )
    return bottomSpacing
}

export const logoType = (svg, image, useImage) => {
    const svgDefault = `<?xml version="1.0" encoding="utf-8"?>
    <!-- Generator: Adobe Illustrator 22.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1" id="LL_Icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 101.9 112.5" style="enable-background:new 0 0 101.9 112.5;" xml:space="preserve">
    <style type="text/css">
        .st0{fill:#D8D8D8;}
        .st1{fill:#20A8D8;}
        .st2{fill:#FFC107;}
    </style>
    <path id="_x31_" class="st0" d="M94.9,73.6L56.3,96.2c-3.3,1.9-7.4,1.9-10.8,0L7,73.6l-4,2.3c-2.8,1.6-3.8,5.1-2.3,8
        c0.5,1,1.3,1.7,2.3,2.3l42.6,24.9c3.3,1.9,7.4,1.9,10.8,0l42.6-24.9c2.8-1.6,3.8-5.1,2.3-8c-0.5-0.9-1.3-1.7-2.3-2.3L94.9,73.6z"/>
    <path id="_x32_" class="st1" d="M94.9,48.8L56.3,71.4c-3.3,1.9-7.4,1.9-10.8,0L7,48.8l-4,2.3c-2.8,1.6-3.8,5.1-2.3,7.9
        C1.2,60,2,60.8,3,61.4l42.6,24.9c3.3,1.9,7.4,1.9,10.8,0l42.6-24.9c2.8-1.6,3.8-5.1,2.3-8c-0.5-0.9-1.3-1.7-2.3-2.3L94.9,48.8z"/>
    <path id="_x33_" class="st2" d="M45.6,61.5L3,36.6c-2.8-1.6-3.8-5.1-2.3-7.9c0.5-1,1.3-1.8,2.3-2.3L45.6,1.5c3.3-1.9,7.4-1.9,10.8,0
        l42.6,24.9c2.8,1.6,3.8,5.1,2.3,8c-0.5,0.9-1.3,1.7-2.3,2.3L56.3,61.5C53,63.5,48.9,63.5,45.6,61.5z"/>
    </svg>
    `
    if (useImage) {
        return (
            (
                image && <div className="logo">
                    <img src={image.mediaItemUrl} alt={image.title} />
                </div>
            )
        )
    }
    const logoSvg = svg ? svg : svgDefault
    return (
        <div className={`logo-svg ${svg ? '' : 'default'}`} dangerouslySetInnerHTML={{ __html: logoSvg.replace(/(width="+[0-9]+p?x?")|(height="+[0-9]+p?x?")/g, '') }}></div>
    )
}

export const getSlug = (slug) => {
    return slug.substring(1, slug.indexOf("/", slug.indexOf("/") + 1))
}

export const replaceStr = (find, replace, replaceString) => {
    if (replaceString) {
        for (var i = 0; i < find.length; i++) {
            replaceString = replaceString.replace(find[i], replace[i]);
        }
        return replaceString;
    }
}

export const useOutsideHandling = (ref, clickOutside) => {
    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                clickOutside();
            }
        }

        const handlePressEsc = (event) => {
            if (ref.current && event.key === 'Escape') {
                clickOutside();
            }
        }

        // Bind the event listener
        document.addEventListener('click', handleClickOutside);
        document.addEventListener('keydown', handlePressEsc);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('keydown', handlePressEsc);
        };
    }, [ref, clickOutside]);
}

export const imageSizeUrl = (nameSize, sizes, sourceDefault) => {
    let sourceImage = sourceDefault;
    if (sizes !== null) {
        const sizeInfor = sizes.find(size => size.name === nameSize)

        if (sizeInfor) {
            sourceImage = sizeInfor.sourceUrl;
        }
    }
    return sourceImage
}

export const addSlashInternalLink = (html) => {
    if (html) {
        const listATag = html.match(/<a\b[^>]*>([\s\S]*?)<\/a>/g);
        let newHtml = html
        if (listATag) {
            listATag.forEach(aTag => {
                let href = aTag.match(/href="(.*?)"/)[1]
                if(href[0] === '#' && href[href.length - 1] === "/" ){
                    href = href.replace('/', '')
                }

                if (href[href.length - 1] !== "/" && href[0] !== '#') {
                    newHtml = html.replace(href, href + "/")
                }
            })
        }
        return newHtml;
    }
    return html;
}

export const addSlashButtonLink = (url) => {
    if (url[url.length - 1] !== "/" && url[url.length - 1] !== '#') {
        url += `/`
    }
    return url
}

export const formatDateCount = (date) => {
    // Get today's date and time
    const dateEnd = new Date(date);
    let daySuffix;

    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    var dayoftheWeek = days[dateEnd.getDay()];
    var month = months[dateEnd.getMonth()];
    var dayoftheMonth = dateEnd.getDate();

    switch (dayoftheMonth) {
        case 1:
        case 21:
        case 31:
            daySuffix = "st";
            break;
        case 2:
        case 22:
            daySuffix = "nd";
            break;
        case 3:
        case 23:
            daySuffix = "rd";
            break;
        default:
            daySuffix = "th";
            break;
    }

    return `by ${dayoftheWeek}, ${month} ${dayoftheMonth + daySuffix}`
}
