/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Header from './header'
import Footer from './footer'
import './layout.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../sass/main.scss'
import 'lightgallery.js/dist/css/lightgallery.css'
import { setDarkMode } from '../actions/common'
import { useDispatch } from 'react-redux'

const Layout = ({ children, darkMode }) => {
     const dispatch = useDispatch()
     const scrollToElement = element => {
          const bodyRect = document.body.getBoundingClientRect()
          const elementRect = element.getBoundingClientRect()
          const offset = elementRect.top - bodyRect.top - 15

          window.scroll({ top: offset, behavior: 'smooth' })
     }

     const handleAnchorAndLinks = () => {
          if (typeof document !== 'undefined') {
               let elementId = window.location.hash.slice(1)
               const elementIdHasEndSlash = elementId[elementId.length - 1]

               if (elementIdHasEndSlash) {
                    elementId = elementId.replace('/', '')
                    window.history.pushState('', document.title, `${window.location.pathname}#${elementId}`)
               }

               if (elementId) {
                    const element = document.getElementById(elementId)

                    if (element) {
                         scrollToElement(element)
                    }
               }

               const anchorLinks = document.querySelectorAll('a[href^="#"]')

               anchorLinks.forEach(anchorLink => {
                    if (anchorLink.getAttribute('href') !== '#') {
                         anchorLink.addEventListener('click', function (e) {
                              e.preventDefault()
                              const hash = e.target.getAttribute('href')

                              if (window.location.hash !== hash) {
                                   window.history.pushState('', document.title, window.location.pathname + hash)
                              }

                              if (hash) {
                                   const elementToScrollTo = document.getElementById(hash.slice(1))

                                   if (elementToScrollTo) {
                                        scrollToElement(elementToScrollTo)
                                   }
                              }
                         })
                    }
               })
          }
     }

     useEffect(() => {
          function nativeSelector() {
               var elements = document.querySelectorAll('body, body *')
               var results = []
               var child
               for (var i = 0; i < elements.length; i++) {
                    child = elements[i].childNodes[0]
                    if (elements[i].hasChildNodes() && child.nodeType === 3) {
                         results.push(child)
                    }
               }
               return results
          }
          var textnodes = nativeSelector(),
               _nv
          for (var i = 0, len = textnodes.length; i < len; i++) {
               _nv = textnodes[i].nodeValue
               textnodes[i].nodeValue = _nv.replace(/listener/gi, 'Listener')
          }

          handleAnchorAndLinks()
     }, []) // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (darkMode) {
               dispatch(setDarkMode(true))
          } else {
               dispatch(setDarkMode(false))
          }
     }, []) // eslint-disable-line react-hooks/exhaustive-deps

     return (
          <>
               <Header />
               <main>{children}</main>
               <Footer />
          </>
     )
}

Layout.propTypes = {
     children: PropTypes.node.isRequired,
}

export default Layout
