import { SET_OPEN_POPUP_SIGN_UP, SET_PACKAGE_ID, SET_IS_LOGIN, SET_DARK_MODE } from './types'

export const setOpenPopupSignUp = openPopupSignUp => {
     return {
          type: SET_OPEN_POPUP_SIGN_UP,
          payload: openPopupSignUp,
     }
}

export const setPackageId = packageId => {
     return {
          type: SET_PACKAGE_ID,
          payload: packageId,
     }
}

export const setIsLogin = isLogin => {
     return {
          type: SET_IS_LOGIN,
          payload: isLogin,
     }
}

export const setDarkMode = isDarkMode => {
     return {
          type: SET_DARK_MODE,
          payload: isDarkMode,
     }
}
