import React from 'react';
import { useSelector } from 'react-redux'
// import CIcon from '@coreui/icons-react';

const Google = ({ className, isIconColor, labelButton, registerMasterClass }) => {
     const url = 'https://accounts.google.com/o/oauth2/auth/oauthchooseaccount';
     const packageId = useSelector(state => state.theme.packageId);
     const options = {
          redirect_uri: `${process.env.GATSBY_APP_PATH}/google-login-redirect`,
          response_type: 'token',
          scope: 'email profile',
          client_id: process.env.GATSBY_APP_GOOGLE_CLIENT_ID,
          prompt: 'select_account',
          flowName: 'GeneralOAuthFlow',
          state: JSON.stringify({ packageId, registerMasterClass })
     }

     let params = '';
     Object.entries(options).forEach(([key, value], index) => {
          if (index === 0) {
               params = `${params}${key}=${value}`;
          } else {
               params = `${params}&${key}=${value}`;
          }
     })

     return (
          <a className={`btn ${className || 'btn-danger'} btn-lg btn-block`} href={`${url}?${params}`} target="_blank" rel="noreferrer">
               {
                    isIconColor ? (
                         <img src="/images/google_icon-icons.com_62736.svg" alt="Google Icon" />
                    ) : (
                              <i className="fab fa-google pr-2"></i>
                         )
               }
               <span>{labelButton}</span>
          </a>
     );
};

export default Google;

// import React from 'react';
// import GoogleLogin from 'react-google-login';
// import { CButton } from '@coreui/react';

// import { callApi } from '../../apiCaller';
// import { AUTH_PATH, GOOGLE_LOGIN } from '../../constants';
// import { toastError } from '../../utils';

// const Google = ({ informParent = f => f }) => {
//      const responseGoogle = (response) => {
//           const data = { idToken: response.tokenId };

//           callApi(GOOGLE_LOGIN, 'POST', data)
//                .then(response => {
//                     if (typeof response == 'undefined') {
//                          return;
//                     }
//                     if (response.status === 200) {
//                          // inform parent component
//                          informParent(response);
//                     } else {
//                          toastError(response);
//                     }
//                })
//      };

//      return (
//           <GoogleLogin
//                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
//                onSuccess={responseGoogle}
//                // onFailure={responseGoogle}
//                redirectUri={`${window.location.origin}${AUTH_PATH.GOOGLE_LOGIN_REDIRECT}`}
//                scope="openid profile email"
//                cookiePolicy="single_host_origin"
//                uxMode="redirect"
//                render={renderProps => (
//                     <CButton
//                          size="lg"
//                          color="danger"
//                          block
//                          onClick={renderProps.onClick}
//                          disabled={renderProps.disabled}
//                     >
//                          <i className="fab fa-google pr-2"></i> Login with Google
//                     </CButton>
//                )}
//           />
//      );
// };

// export default Google;
