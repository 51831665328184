/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'
import { SCRIPT_GTM_PROD, SCRIPT_GTM_STAGING } from '../constants'

function SEO({
     title,
     description,
     lang,
     classBody,
     canonical,
     robotsDirective,
     ogType,
     ogTitle,
     ogDescription,
     ogImage,
     twitterCard,
     breadcrumbItems,
}) {
     const { wp } = useStaticQuery(graphql`
          query {
               wp {
                    acfOptionsSeoSocial {
                         cfSeoAndSocialDefaultSetting {
                              defaultSeoFields {
                                   robots
                              }
                              defaultSeoFieldsCopy {
                                   articlePublisher
                                   ogDescription
                                   ogLocale
                                   ogTitle
                                   ogType
                                   twitterCard
                                   twitterCreator
                                   twitterSite
                                   ogImage {
                                        ...WordpressImageFields
                                   }
                              }
                         }
                    }
                    acfOptionsCodePlacement {
                         cfCodePlacement {
                              insideHead
                         }
                    }
               }
          }
     `)

     const defaultRobot = wp.acfOptionsSeoSocial.cfSeoAndSocialDefaultSetting.defaultSeoFields.robots
     const defaultSeo = wp.acfOptionsSeoSocial.cfSeoAndSocialDefaultSetting.defaultSeoFieldsCopy
     const scriptGTM = process.env.GATSBY_ENV === 'product' ? SCRIPT_GTM_PROD : SCRIPT_GTM_STAGING

     const imageSeoTag = ogImage ? ogImage.mediaItemUrl : defaultSeo.ogImage ? defaultSeo.ogImage.mediaItemUrl : null

     let breadcrumbContentSchema = ''

     if (breadcrumbItems) {
          breadcrumbItems.forEach((breadcrumbItem, index) => {
               const url = breadcrumbItem.url ? `,"item": "${process.env.GATSBY_SITE_URL}/${breadcrumbItem.url}"` : ''
               breadcrumbContentSchema += `,{
                "@type": "ListItem",
                "position": ${index + 2},
                "name": "${breadcrumbItem.name}"
                ${url}
              }`
          })
     }

     const listScript = wp.acfOptionsCodePlacement.cfCodePlacement.insideHead.match(/<script\b[^>]*>([\s\S]*?)<\/script>/g)
     let newListScript = []

     listScript.forEach(script => {
          if (script.indexOf('<script') > -1) {
               let newScript = {}
               newScript.code = script.match(/<script\b[^>]*>([\s\S]*?)<\/script>/)[1]
               if (script.indexOf('src=') > -1) {
                    let src = script.match(/(src="[\s\S]+")/g)
                    src = src[0].match(/[^src="]([\s\S]+)[^"]/g)
                    newScript.src = src[0]
               }
               if (script.match(/<script\b[^>]*>/)[0].indexOf('async') > -1) {
                    newScript.async = true
               }
               if (script.match(/<script\b[^>]*>/)[0].indexOf('defer') > -1) {
                    newScript.defer = true
               }
               newListScript.push(newScript)
          }
     })

     useEffect(scriptGTM, [])

     let robotMeta = robotsDirective || defaultRobot

     if (process.env.GATSBY_ENV !== 'product') {
          robotMeta = 'noindex'
     }

     const linkFonts = [
          {
               rel: `preconnect`,
               href: `https://fonts.gstatic.com`,
          },
          {
               rel: `stylesheet`,
               href: `https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap`,
          },
          {
               rel: `stylesheet`,
               href: `https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet`,
          },
          {
               rel: `stylesheet`,
               href: `https://fonts.googleapis.com/css2?family=Borel&display=swap" rel="stylesheet`,
          },
     ]

     if (classBody?.includes('podcast-main-page')) {
          linkFonts.push({
               rel: `stylesheet`,
               href: `https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap`,
          })
     }

     return (
          <Helmet
               htmlAttributes={{
                    lang,
               }}
               bodyAttributes={{ class: classBody }}
               title={ReactHtmlParser(title || defaultSeo.ogTitle).toString()}
               meta={[
                    {
                         name: `description`,
                         content: description || defaultSeo.ogDescription,
                    },
                    {
                         name: `canonical`,
                         content: canonical,
                    },
                    {
                         name: `robots`,
                         content: robotMeta,
                    },
                    {
                         property: `og:locale`,
                         content: defaultSeo.ogLocale,
                    },
                    {
                         property: `og:title`,
                         content: ReactHtmlParser(ogTitle || defaultSeo.ogTitle).toString(),
                    },
                    {
                         property: `og:description`,
                         content: ogDescription || defaultSeo.ogDescription,
                    },
                    {
                         property: `og:Image`,
                         content: imageSeoTag,
                    },
                    {
                         property: `og:type`,
                         content: ogType || defaultSeo.ogType,
                    },
                    {
                         name: `twitter:card`,
                         content: twitterCard || defaultSeo.twitterCard,
                    },
                    {
                         name: `twitter:creator`,
                         content: defaultSeo.twitterCreator,
                    },
                    {
                         name: `twitter:site`,
                         content: defaultSeo.twitterSite,
                    },
                    {
                         name: `article:publisher`,
                         content: defaultSeo.articlePublisher,
                    },
               ]}
               link={linkFonts}
          >
               {breadcrumbContentSchema && (
                    <script type="application/ld+json">{`
                        {
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [{
                            "@type": "ListItem",
                            "position": 1,
                            "name": "Home",
                            "item": "${process.env.GATSBY_SITE_URL}"
                            }${breadcrumbContentSchema}]
                        }
                    `}</script>
               )}
               {newListScript.map((script, index) => {
                    if (script.src) {
                         if (script.async) {
                              return <script key={index} src={script.src ? script.src : null} async></script>
                         }
                         if (script.defer) {
                              return <script key={index} src={script.src ? script.src : null} defer></script>
                         }
                         return <script key={index} src={script.src ? script.src : null}></script>
                    }
                    return <script key={index}>{script.code}</script>
               })}
          </Helmet>
     )
}

SEO.defaultProps = {
     lang: `en`,
}

SEO.propTypes = {
     description: PropTypes.string,
     lang: PropTypes.string,
     meta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
